import { projectId, wagmiAdapter } from '@/utils/WagmiConfig';
import { polygon, immutableZkEvm, immutableZkEvmTestnet, polygonAmoy } from '@reown/appkit/networks';
import { createAppKit } from '@reown/appkit/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type ReactNode } from 'react';
import { type Config, WagmiProvider } from 'wagmi';

// Set up queryClient
const queryClient = new QueryClient();

if (!projectId) {
  throw new Error('Project ID is not defined');
}

// Set up metadata
const metadata = {
  name: 'Landrocker.io',
  description: 'Landrocker.io',
  url: process.env.NEXT_PUBLIC_HOSTNAME || 'https://landrocker.io/',
  icons: ['https://avatars.githubusercontent.com/u/179229932'],
};

const modal = createAppKit({
  debug: true,
  adapters: [wagmiAdapter],
  projectId,
  networks: [polygon, immutableZkEvm],
  metadata: metadata,
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
    email: false, // default to true
    socials: [],
    allWallets: true,
    emailShowWallets: true, // default to true
    swaps: false,
  },
  enableInjected: true,
  enableWalletConnect: true,
  allowUnsupportedChain: false,
  featuredWalletIds: [
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    'aba1f652e61fd536e8a7a5cd5e0319c9047c435ef8f7e907717361ff33bb3588',
  ],
});

function ContextProvider({ children }: { children: ReactNode }) {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig as Config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}

export default ContextProvider;
