import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { polygon, immutableZkEvm, immutableZkEvmTestnet, polygonAmoy } from '@reown/appkit/networks';

import { CreateConnectorFn, http } from 'wagmi';

export const projectId = process.env.NEXT_PUBLIC_APPKIT_PROJECT_ID;

if (!projectId) {
  throw new Error('Project ID is not defined');
}

export const connectors: CreateConnectorFn[] = [];

export const networks = [polygon, immutableZkEvm, immutableZkEvmTestnet, polygonAmoy];

const chains = [polygon, immutableZkEvm, immutableZkEvmTestnet, polygonAmoy] as const;

export const wagmiAdapter = new WagmiAdapter({
  networks,
  ssr: true,
  projectId,
  chains,
  transports: {
    [polygon.id]: http(),
    [polygonAmoy.id]: http(),
    [immutableZkEvmTestnet.id]: http(),
    [immutableZkEvm.id]: http(),
  },
  connectors,
});

export const clientConfig = wagmiAdapter.wagmiConfig;
